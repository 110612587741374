import { useEffect, useState } from 'react'
import { getPartnerApi } from '../../../../api/home'
import { PartnerProps } from '../../../typing/type'
import './index.css'
export default function Partner() {
  const [partners, setPartners] = useState<PartnerProps[]>([])
  useEffect(() => {
    getPartnerApi().then((res) => {
      if (res.data && res.data.code === 200) {
        const data = res.data.data || []
        setPartners(data.slice(0, 12))
      }
    })
  }, [])
  return (
    <div className="home_section">
      <div className="home_partner">
        <div className="home_partner_main">
          <div className="hpc_title">
            <p>客户与伙伴</p>
            <p>PARTNER</p>
          </div>
          <div className="hpc_content">
            <ul className="hpc_img_list">
              {partners.map((d) => (
                <li key={d.cooperativePartnerId}>
                  <img alt="" src={d.companyCoverUrl} title={d.companyName}/>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
