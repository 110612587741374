import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router'
import Home from './page/home'
import Main from './page/main'
import News from './page/main/news'
import Product from './page/main/product'
import ProductDetail from './page/main/productDetail'
function App() {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="main" element={<Main />}>
        <Route path="/main/news" element={<News />} />
        <Route path="/main/product" element={<Product />} />
        <Route path="/main/productDetail" element={<ProductDetail />} />
      </Route>
    </Routes>
  )
}

export default App
