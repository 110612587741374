import { useEffect } from 'react'
import { Outlet } from 'react-router'
// import Contact from '../home/components/contact'
import Header from './header'
export default function Main() {
  useEffect(() => {
    //修改body
    document.getElementsByTagName('body')[0].className = ''
  }, [])
  return (
    <div>
      <Header />
      <div>
        <Outlet />
      </div>
      {/* 联系 */}
      {/* <Contact /> */}
    </div>
  )
}
