import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getDataDictByTypeIdApi } from '../../../api/dataDict'
import { getNewsByTypeApi } from '../../../api/home'
import { DictTypeProps, NewsProps } from '../../typing/type'
import './index.css'
export default function News() {
  const [news, setNews] = useState<NewsProps[]>([])
  const [menus, setMenus] = useState<DictTypeProps[]>([])
  const [current, setCurrent] = useState<DictTypeProps>({
    dictId: '0',
    name: '全部',
    value: '',
  })

  const getNewsList = (type: string) => {
    getNewsByTypeApi(type).then((res) => {
      if (res.data && res.data.code === 200) {
        const data = res.data.data || []
        setNews(data)
      }
    })
  }

  const moreBtnClcikHandle = (menu: DictTypeProps) => {
    getNewsList(menu.value)
    setCurrent(menu)
  }

  const newItemClickHandle = (newsItem: NewsProps) => {
    window.open(newsItem.detailLink, '_blank')
  }

  useEffect(() => {
    getDataDictByTypeIdApi(3).then((res) => {
      if (res.data && res.data.code === 200) {
        const data = res.data.data || []
        const tempMenus: DictTypeProps[] = []
        tempMenus.push({
          dictId: '0',
          name: '全部',
          value: '',
        })
        data.forEach((d: any) => {
          tempMenus.push({
            dictId: d.dictId,
            name: d.description,
            value: d.value,
          })
        })
        setMenus(tempMenus)
      }
    })
  }, [])

  useEffect(() => {
    getNewsList(current.value)
  }, [current])
  return (
    <div className="main_news">
      <div className="m_n_title">
        <p>NEWS CENTER</p>
        <div className="m_n_t_line"></div>
        <p>新闻中心</p>
      </div>
      <div className="m_n_crumb">
        当前位置:&nbsp;&nbsp;<Link to="/">首页</Link>
        &nbsp;&nbsp;&gt;&nbsp;&nbsp;<Link to="/main/news">新闻中心</Link>
      </div>
      <div className="m_n_content">
        <ul className="m_n_menu">
          {menus.map((d) => (
            <li
              key={d.dictId}
              onClick={() => moreBtnClcikHandle(d)}
              className={d.value === current.value ? 'm_n_menu_active' : ''}
            >
              &gt;&nbsp;&nbsp;{d.name}
            </li>
          ))}
        </ul>
        <ul className="m_n_list">
          {news.map((d) => (
            <li
              key={d.newsIntroductionId}
              onClick={() => newItemClickHandle(d)}
            >
              <img alt="" src={d.coverUrl} />
              <div className="m_n_detail">
                <p>{d.title}</p>
                <p>{d.introduction}</p>
                <p>{d.typeName}</p>
                <p>{d.updateTime}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
