import axios from 'axios'

const getDataDictByTypeIdApi = (typeId: number) => {
  return axios.get('/v1.0/sysDict/list', {
    params: {
      typeId: typeId,
    },
  })
}

export { getDataDictByTypeIdApi }
