import { useEffect, useState } from 'react'
import { getAboutInfoApi } from '../../../../api/home'
import './index.css'
export default function About() {
  const [content, setContent] = useState('')
  useEffect(() => {
    getAboutInfoApi().then((res) => {
      if (res.data && res.data.code === 200) {
        const data = res.data.data
        setContent(data.content || '')
      }
    })
  }, [])

  return (
    <div className="home_section">
      <div className="about_content">
        <div className="about_img_one">
          <img alt="" src={require('../../assets/img/about_one.png').default} />
          <p className="a_i_o_p1">关于</p>
          <p className="a_i_o_p2">ABOUT US</p>
          <div className="a_i_o_line"></div>
        </div>
        <div className="about_introduce">
          <div className="about_intd_title">company</div>
          <div className="about_intd_content">{content}</div>
        </div>
        <div className="about_img_p">
          <div className="about_img_two">
            <img alt="" src={require('../../assets/img/about_two.png').default} />
          </div>
          <div className="about_img_three">
            <img alt="" src={require('../../assets/img/about_three.png').default} />
          </div>
        </div>
      </div>
    </div>
  )
}
