/**
 * 防抖
 * @param func
 * @param delay
 */
const debounce = <T extends object>(func: Function, delay: number) => {
  let task: any = null
  return (args: T) => {
    if (task) {
      clearTimeout(task)
    }
    task = setTimeout(() => {
      func(args)
    }, delay)
  }
}

export { debounce }
