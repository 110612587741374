import { useEffect, useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { getDataDictByTypeIdApi } from '../../../api/dataDict'
import { getProductListByTypeApi } from '../../../api/home'
import { DictTypeProps, ProductProps } from '../../typing/type'
import './index.css'
export default function Product() {
  const navigate = useNavigate()
  const [products, setProducts] = useState<ProductProps[]>([])
  const [menus, setMenus] = useState<DictTypeProps[]>([])
  const [current, setCurrent] = useState<DictTypeProps>({
    dictId: '0',
    name: '全部',
    value: '',
  })
  const getNewsList = (type: string) => {
    getProductListByTypeApi(type).then((res) => {
      if (res.data && res.data.code === 200) {
        const data = res.data.data || []
        setProducts(data)
      }
    })
  }

  const productClickHandle = (product: ProductProps) => {
    navigate('/main/productDetail', { replace: true, state: product })
  }

  const moreBtnClcikHandle = (menu: DictTypeProps) => {
    getNewsList(menu.value)
    setCurrent(menu)
  }

  useEffect(() => {
    getDataDictByTypeIdApi(1).then((res) => {
      if (res.data && res.data.code === 200) {
        const data = res.data.data || []
        const tempMenus: DictTypeProps[] = []
        tempMenus.push({
          dictId: '0',
          name: '全部',
          value: '',
        })
        data.forEach((d: any) => {
          tempMenus.push({
            dictId: d.dictId,
            name: d.description,
            value: d.value,
          })
        })
        setMenus(tempMenus)
      }
    })
  }, [])

  useEffect(() => {
    getNewsList(current.value)
  }, [current])
  return (
    <div className="main_product">
      <div className="m_p_title">
        <p>PRODUCT SERVICE</p>
        <div className="m_p_t_line"></div>
        <p>产品服务</p>
      </div>
      <div className="m_p_crumb">
        当前位置:&nbsp;&nbsp;<Link to="/">首页</Link>
        &nbsp;&nbsp;&gt;&nbsp;&nbsp;<Link to="/main/product">产品服务</Link>
      </div>
      <div className="m_p_content">
        <ul className="m_p_menu">
          {menus.map((d) => (
            <li
              key={d.dictId}
              onClick={() => moreBtnClcikHandle(d)}
              className={d.value === current.value ? 'm_p_menu_active' : ''}
            >
              &gt;&nbsp;&nbsp;{d.name}
            </li>
          ))}
        </ul>
        <ul className="m_p_list">
          {products.map((d) => (
            <li key={d.productServiceId} onClick={() => productClickHandle(d)}>
              <img alt="" src={d.coverUrl} />
              <div>{d.name}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
