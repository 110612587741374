import { MenusProps } from '../../../typing/type'
import './index.css'

export default function index(props: MenusProps) {
  const { menus, current, onChanged } = props

  return (
    <ul className="pgna_bar">
      {menus.map((d) => (
        <li className={d === current ? 'pagi_nav_active' : ''} key={d} onClick={() => onChanged(d)}></li>
      ))}
    </ul>
  )
}
