import { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import './index.css'

interface CustomizedState {
    detailUrl: string
}

export default function ProductDetail() {
    const location = useLocation()
    const state = location.state as CustomizedState;
    const [url, setUrl] = useState('')

    useEffect(() => {
        if (state.detailUrl) {
            setUrl(state.detailUrl)
        }
    }, [state])

    return (
        <div className="product_detail">
            <div className="p_d_crumb">
                当前位置:&nbsp;&nbsp;<Link to="/">首页</Link>
                &nbsp;&nbsp;&gt;&nbsp;&nbsp;<Link to="/main/product">产品服务</Link>
                &nbsp;&nbsp;&gt;&nbsp;&nbsp;<Link to="/main/productDetail">产品详情</Link>
            </div>
            <img alt="" src={url} />
        </div>
    )
}
