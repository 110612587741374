import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getNewsListApi } from '../../../../api/home'
import { NewsProps } from '../../../typing/type'
import './index.css'

export default function News() {
  const [news, setNews] = useState<NewsProps[]>([])
  const navigate = useNavigate()
  const moreBtnClcikHandle = () => {
    navigate('./main/news', { replace: true })
  }

  const newItemClickHandle = (newsItem: NewsProps) => {
    window.open(newsItem.detailLink, '_blank')
  }

  const getNewsList = () => {
    getNewsListApi().then((res) => {
      if (res.data && res.data.code === 200) {
        const data = res.data.data || []
        setNews(data.slice(0, 3))
      }
    })
  }

  useEffect(() => {
    getNewsList()
  }, [])

  return (
    <div className="home_section">
      <div className="news_content">
        <div className="news_header">
          <p className="news_title_p1">新闻</p>
          <p className="news_title_p2">NEWS</p>
          <div className="news_title_line"></div>
        </div>
        <ul className="news_imgs_swiper">
          {news.map((d) => (
            <li
              key={d.newsIntroductionId}
              onClick={() => newItemClickHandle(d)}
            >
              <img alt="" src={d.coverUrl} />
            </li>
          ))}
        </ul>
        <ul className="news_article">
          {news.map((d) => {
            return (
              <li key={d.newsIntroductionId}>
                <p className="news_art_title">{d.title}</p>
                {/* <p className="news_art_loction">中国-上海</p> */}
                <p className="news_art_text" title={d.introduction}>{d.introduction}</p>
              </li>
            )
          })}
        </ul>
        <div className="news_more_btn" onClick={moreBtnClcikHandle}>
          更多&nbsp;&nbsp;&nbsp;&gt;&gt;
        </div>
      </div>
    </div>
  )
}
