import { useState, useEffect } from 'react'
import { getBannerListApi } from '../../../../api/home'
import { BannerProps, MenusProps } from '../../../typing/type'
import './index.css'
export default function Banner(props: MenusProps) {
  const { menus, current, onChanged } = props
  const [banner, setBanner] = useState<string>()
  const [banners, setBanners] = useState<BannerProps[]>([])
  const [left, setLeft] = useState(0)

  const changeIndexByBtn = (step: string) => {
    const clientWidth = document.documentElement.clientWidth
    const index = banners.findIndex((d) => d.bannerId === banner)
    if (banner === banners[0]?.bannerId && step === 'left') {
      return
    } else if (
      banner === banners[banners.length - 1]?.bannerId &&
      step === 'right'
    ) {
      return
    } else if (step === 'right') {
      setBanner(banners[index + 1].bannerId)
      setLeft(left - clientWidth)
    } else if (step === 'left') {
      setBanner(banners[index - 1].bannerId)
      setLeft(left + clientWidth)
    }
  }

  // const changeIndexByPagination = (bannerId: string) => {
  //   setBanner(bannerId)
  // }

  const getBannerList = () => {
    getBannerListApi().then((res) => {
      if (res.data && res.data.code === 200) {
        const data = res.data.data || []
        setBanners(data)
      }
    })
  }

  useEffect(() => {
    if (banners.length > 0) {
      setBanner(banners[0].bannerId)
    }
  }, [banners])

  useEffect(() => {
    getBannerList()
  }, [])

  return (
    <div className="home_section">
      <div className="home_header">
        <span className="home_header_logo"></span>
        <ul className="home_header_nav">
          {menus.map((d) => (
            <li
              className={d === current ? 'home_nav_active' : ''}
              key={d}
              onClick={() => onChanged(d)}
            >
              {d}
            </li>
          ))}
        </ul>
      </div>
      <div className="home_banner">
        <div
          className="home_b_content"
          style={{ width: `${banners.length * 100}%`, left: left }}
        >
          {banners.map((d) => {
            const ext = d.url.split('.')[1]
            if (ext === 'jpg' || ext === 'png' || ext === 'jpeg') {
              return (
                <img
                  key={d.bannerId}
                  className="home_b_content_item"
                  style={{ width: `${(1 / banners.length) * 100}%` }}
                  alt=""
                  src={d.url}
                />
              )
            } else if (ext === 'mp4') {
              return (
                <div
                  key={d.bannerId}
                  className="home_b_content_item"
                  style={{ width: `${(1 / banners.length) * 100}%` }}
                >
                  <video id={d.bannerId} className="home_b_video" controls>
                    <source
                      src={d.url}
                      type="video/mp4"
                    />
                  </video>
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
        <div
          className="home_b_left"
          onClick={() => changeIndexByBtn('left')}
        ></div>
        <div
          className="home_b_right"
          onClick={() => changeIndexByBtn('right')}
        ></div>
        <ul className="home_b_pagination">
          {banners.map((d) => (
            <li
              key={d.bannerId}
              className={d.bannerId === banner ? 'home_b_pagi_active' : ''}
              // onClick={() => changeIndexByPagination(d.bannerId)}
            ></li>
          ))}
        </ul>
      </div>
    </div>
  )
}
