import React, { Component } from 'react'
import './index.css'
import Pagination from './components/pagination'
import Banner from './components/banner'
import Product from './components/product'
import About from './components/about'
import News from './components/news'
import Partner from './components/partner'
import Contact from './components/contact'
import { debounce } from '../../common/utils'
const menus: string[] = ['首页', '产品', '关于', '新闻', '合作', '联系']

interface IHomeState {
  current: string
}
export default class Home extends Component<{}, IHomeState> {
  constructor(props: any) {
    super(props)
    this.state = {
      current: menus[0],
    }
  }

  windowResizeHandle = () => {
    this.getOffsetTop(this.state.current)
  }

  menuChangeHandle = (menu: string) => {
    this.setState({ current: menu })
    this.getOffsetTop(menu)
    //存储sessionStorage
    sessionStorage.setItem('lastMenu', menu)
  }

  getOffsetTop = (menu: string) => {
    //翻页
    const clientHeight =
      document.getElementsByClassName('home_section')[0]?.clientHeight
    const index = menus.findIndex((d) => d === menu)
    let offset = index * clientHeight
    document.documentElement.scrollTop = offset
  }

  mouseSrollHandle = () => {
    const clientHeight =
      document.getElementsByClassName('home_section')[0]?.clientHeight
    const scrollTop = window.scrollY
    const range = scrollTop / clientHeight
    let currentMenu = this.state.current
    if (range <= 0.5) {
      currentMenu = menus[0]
    } else if (range > 0.5 && range <= 1.5) {
      currentMenu = menus[1]
    } else if (range > 1.5 && range <= 2.5) {
      currentMenu = menus[2]
    } else if (range > 2.5 && range <= 3.5) {
      currentMenu = menus[3]
    } else if (range > 3.5 && range <= 4.5) {
      currentMenu = menus[4]
    } else if (range > 4.5 && range <= 5.5) {
      currentMenu = menus[5]
    }
    this.setState({ current: currentMenu })
    sessionStorage.setItem('lastMenu', currentMenu)
  }

  componentDidMount() {
    //监听窗口变化
    window.addEventListener('resize', debounce(this.windowResizeHandle, 100))
    //监听鼠标滚轮
    window.addEventListener('scroll', debounce(this.mouseSrollHandle, 100))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.windowResizeHandle, 100))
    window.removeEventListener('scroll', debounce(this.mouseSrollHandle, 100))
  }

  render() {
    return (
      <React.Fragment>
        {/* 切换 */}
        <Pagination
          current={this.state.current}
          menus={menus}
          onChanged={this.menuChangeHandle}
        />
        <div className="home">
          {/* 首屏 */}
          <Banner
            current={this.state.current}
            menus={menus}
            onChanged={this.menuChangeHandle}
          />
          {/* 产品 */}
          <Product />
          {/* 关于 */}
          <About />
          {/* 新闻 */}
          <News />
          {/* 合作 */}
          <Partner />
          {/* 联系 */}
          <Contact />
        </div>
      </React.Fragment>
    )
  }
}
