import axios from 'axios'

const getProductListByTypeApi = (type: string) => {
  return axios.get('/v1.0/productService/list', {
    params: {
      type: type,
    },
  })
}


const getBannerListApi = () => {
  return axios.get('/v1.0/banner/list')
}

const getNewsListApi = () => {
  return axios.get('/v1.0/newsIntroduction/list')
}

const getNewsByTypeApi = (type: string) => {
  return axios.get('/v1.0/newsIntroduction/list', {
    params: {
      type: type,
    },
  })
}

const getAboutInfoApi = () => {
  return axios.get('/v1.0/aboutUs/detail')
}

const getPartnerApi = () => {
  return axios.get('/v1.0/cooperativePartner/list')
}

const getContactApi = () => {
  return axios.get('/v1.0/contactUs/detail')
}

export {
  getProductListByTypeApi,
  getNewsListApi,
  getAboutInfoApi,
  getPartnerApi,
  getContactApi,
  getNewsByTypeApi,
  getBannerListApi
}
