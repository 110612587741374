import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getDataDictByTypeIdApi } from '../../../../api/dataDict'
import { getProductListByTypeApi } from '../../../../api/home'
import { ProductProps, DictTypeProps } from '../../../typing/type'
import './index.css'

export default function Product() {
  const [menus, setMenus] = useState<DictTypeProps[]>([])
  const [current, setCurrent] = useState<DictTypeProps>({
    dictId: '0',
    name: '全部',
    value: '',
  })
  const [products, setProducts] = useState<ProductProps[]>([])
  const navigate = useNavigate()
  const moreBtnClcikHandle = (menu: DictTypeProps) => {
    if (menu.name === '更多') {
      navigate('./main/product', { replace: true })
    } else {
      getProductList(menu.value)
      setCurrent(menu)
    }
  }

  const productClickHandle = (product: ProductProps) => {
    navigate('./main/productDetail', { replace: true, state: product })
  }

  const getProductList = (value: string) => {
    getProductListByTypeApi(value).then((res) => {
      if (res.data && res.data.code === 200) {
        const data = res.data.data || []
        setProducts(data.slice(0, 6))
      }
    })
  }

  useEffect(() => {
    getDataDictByTypeIdApi(1).then((res) => {
      if (res.data && res.data.code === 200) {
        const data = res.data.data || []
        const tempMenus: DictTypeProps[] = []
        tempMenus.push({
          dictId: '0',
          name: '全部',
          value: '',
        })
        data.forEach((d: any) => {
          tempMenus.push({
            dictId: d.dictId,
            name: d.description,
            value: d.value,
          })
        })
        tempMenus.push({
          dictId: '-1',
          name: '更多',
          value: '-1',
        })
        setMenus(tempMenus)
      }
    })
  }, [])

  useEffect(() => {
    getProductList(current.value)
  }, [current])

  return (
    <div className="home_section">
      <div className="product_content">
        <div className="product_header">
          <div>
            <span className="p_t_chinese">产品服务</span>
            <span className="p_t_english">Business sector</span>
          </div>
          <ul className="product_nav">
            {menus.map((d) => (
              <li
                key={d.dictId}
                onClick={() => moreBtnClcikHandle(d)}
                className={
                  d.value === current.value ? 'product_nav_active' : ''
                }
              >
                {d.name}
              </li>
            ))}
          </ul>
        </div>
        <ul className="product_list">
          {products.map((p) => (
            <li
              key={p.productServiceId}
              className="p_l_item"
              onClick={() => productClickHandle(p)}
            >
              <div className="p_l_i_img">
                <img alt="" src={p.coverUrl} />
              </div>
              <div className="p_l_i_title">{p.name}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
